import { PrankColor } from '@pafcloud/style';

export const Colors = {
  Text: PrankColor.WhiteTransparent90,
  TextHover: PrankColor.White,
  TextActive: PrankColor.Primary,
  TextShadow: `0 1px ${PrankColor.Shadow}`,

  Icon: undefined,
  IconActive: undefined,

  Background: undefined,
  BackgroundHover: `${PrankColor.Blue}33`,
  BackgroundActive: undefined,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: undefined,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: undefined,
  Weight: 'bold',
  WeightHover: 'bold',
  WeightActive: 'bold',
} as const;
