import { PrankColor } from '@pafcloud/style';

export const Colors = {
  Text: PrankColor.White,
  DepositQuickButtons: PrankColor.Primary,
  DepositQuickButtonsHover: PrankColor.PrimaryHighlight,
  Border: undefined,
  Background: PrankColor.Gradient,
  SidebarContent: undefined,
  SidebarTopBackground: 'transparent',
  SidebarOverlay: undefined,
  EmphasizedLink: PrankColor.Primary,
};
