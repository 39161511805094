import { PrankColor } from '@pafcloud/style';

export const Colors = {
  Text: PrankColor.Darker,
  MessageSeenText: PrankColor.DarkerTransparent50,
  PaginationText: PrankColor.Darker,

  BorderColor: PrankColor.DarkerTransparent10,

  Highlight: `${PrankColor.LinkText}0D`, //5% opacity
  HighlightBorder: PrankColor.LinkText,
};
