import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const PrankBrandColors = {
  // Primary Colors
  Primary: '#E2E504',
  PrimaryLight: '#F5F836',
  PrimaryHighlight: '#FEFF8E',

  // Alternate Colors
  Alternate: '#FF206E',
  AlternateLight: '#FE4586',
  AlternateHighlight: '#D9356F',

  //Gradient blue
  Blue: '#2863D8',
  BlueLight: '#f2f5f9',
  BlueBlend: '#2c8de4',
  BlueCyan: '#35DCFB',

  // Neutral Colors
  Dark: '#898F8E',
  Darker: '#131F1E',
  Neutral: '#787F83',
  Lighter: '#F7F7F7',
  White: '#ffffff',

  // Extra
  Aux: '#FF206E',
};

const Primitive: PrimitiveColorType = {
  Primary: PrankBrandColors.Primary,
  PrimaryTint: PrankBrandColors.PrimaryHighlight,
  PrimaryContrast: PrankBrandColors.Darker,

  Secondary: PrankBrandColors.Alternate,
  SecondaryTint: PrankBrandColors.AlternateLight,
  SecondaryContrast: PrankBrandColors.White,

  Accent: PrankBrandColors.Blue,
  AccentTint: PrankBrandColors.BlueBlend,
  AccentContrast: PrankBrandColors.White,

  Gradient: `linear-gradient(90deg, ${PrankBrandColors.BlueCyan}, ${PrankBrandColors.Blue})`,
  GradientTint: `linear-gradient(90deg, ${PrankBrandColors.BlueCyan}, ${PrankBrandColors.BlueBlend})`,
  GradientContrast: PrankBrandColors.White,
};

const TextColors: TextColorType = {
  HeadingText: PrankBrandColors.Blue,
  BodyText: PrankBrandColors.Darker,
  MutedText: PrankBrandColors.Neutral,
  HighlightedText: PrankBrandColors.Blue,
  LinkText: PrankBrandColors.Blue,
  ErrorText: PrankBrandColors.Aux,
  DisabledText: '#9B9DA0',
};

const Surface: SurfaceColorType = {
  Base: {
    Background: createTransparentColor(PrankBrandColors.White, 0.98),
    Foreground: PrankBrandColors.Darker,
    Dimmed: createTransparentColor(PrankBrandColors.Blue, 0.3),
  },
  Nested: {
    Background: PrankBrandColors.BlueLight,
    Foreground: PrankBrandColors.Darker,
    Dimmed: createTransparentColor(PrankBrandColors.Blue, 0.2),
  },
  Disabled: {
    Background: PrankBrandColors.Dark + '80',
    Foreground: PrankBrandColors.Neutral,
    Dimmed: createTransparentColor(PrankBrandColors.Dark, 0.15),
  },
  Floating: {
    Background: PrankBrandColors.Lighter,
    Foreground: PrankBrandColors.Darker,
    Dimmed: createTransparentColor(PrankBrandColors.Dark, 0.2),
  },
};

const Signal: SignalColorType = {
  Success: PrankBrandColors.Primary,
  SuccessContrast: PrankBrandColors.White,

  Info: PrankBrandColors.BlueBlend,
  InfoContrast: PrankBrandColors.White,

  Attention: PrankBrandColors.BlueBlend,
  AttentionContrast: PrankBrandColors.White,

  Danger: PrankBrandColors.Aux,
  DangerContrast: PrankBrandColors.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ElevationType = {
  Level1: `0 2px 3px -1px var(--shadow-color, ${createTransparentColor(PrankBrandColors.Darker, 0.1)})`,
  Level2: `0 4px 6px -1px var(--shadow-color, ${createTransparentColor(PrankBrandColors.Darker, 0.15)})`,
  Level3: `0 8px 18px -2px var(--shadow-color, ${createTransparentColor(PrankBrandColors.Darker, 0.15)}), 0 3px 6px 0 var(--shadow-color, ${createTransparentColor(PrankBrandColors.Darker, 0.15)})`,
};

const General: GeneralType = {
  Bonus: PrankBrandColors.Primary,
  BonusContrast: PrankBrandColors.Darker,
};

const Layout: LayoutColors = {
  Background: PrankBrandColors.Lighter,
  BrandBorder: Primitive.Gradient,
  BrowserTheme: Primitive.Accent,
  LoadingColor: Primitive.Primary,
  Overlay: Primitive.Accent + 'CC',
  Sidebar: {
    Background: Primitive.Gradient,
    Foreground: PrankBrandColors.White,
  },
};

const Hero: HeroColorType = {
  Heading: PrankBrandColors.White,
  Text: PrankBrandColors.White,
  TextHighlight: Primitive.Primary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Primary,
    Hover: Primitive.PrimaryTint,
    Active: Primitive.PrimaryTint,
    Text: Primitive.PrimaryContrast,
  },
};

export const Color = {
  ...PrankBrandColors,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: PrankBrandColors.Lighter,
  Gradient: `linear-gradient(45deg, ${PrankBrandColors.BlueCyan}, ${PrankBrandColors.Blue})`,

  // Shadows, highlight and transparency
  Shadow: createTransparentColor(PrankBrandColors.Darker, 0.31),
  ShadowInset: createTransparentColor(PrankBrandColors.Lighter, 0.2),

  FocusHighlight: createTransparentColor(PrankBrandColors.Primary, 0.33),

  PrimaryTransparent50: createTransparentColor(PrankBrandColors.Primary, 0.5),
  AlternateTransparent50: createTransparentColor(PrankBrandColors.Alternate, 0.5),

  BlueCyanTransparent25: createTransparentColor(PrankBrandColors.BlueCyan, 0.25),
  BlueTransparent50: createTransparentColor(PrankBrandColors.Blue, 0.5),

  NeutralTransparent50: createTransparentColor(PrankBrandColors.Neutral, 0.5),

  LighterTransparent25: createTransparentColor(PrankBrandColors.Lighter, 0.25),
  LighterTransparent50: createTransparentColor(PrankBrandColors.Lighter, 0.5),

  WhiteTransparent50: createTransparentColor(PrankBrandColors.White, 0.5),
  WhiteTransparent90: createTransparentColor(PrankBrandColors.White, 0.9),

  DarkerTransparent10: createTransparentColor(PrankBrandColors.Darker, 0.1),
  DarkerTransparent50: createTransparentColor(PrankBrandColors.Darker, 0.5),
  DarkerTransparent70: createTransparentColor(PrankBrandColors.Darker, 0.7),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart}, ${ColorEnd})`,
  AdaptiveBackground: `linear-gradient(90deg, ${PrankBrandColors.BlueCyan}, ${PrankBrandColors.Blue})`,

  // Theme color for mobile browsers
  BrowserTheme: PrankBrandColors.Blue,
};
