/**
 * @generated SignedSource<<ae751b1337db6cfda2a50a8dff7dd0eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SidebarQuirky_content$data = {
  readonly site: {
    readonly menu: {
      readonly search: {
        readonly gameListId: string | null;
      };
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SidebarVerificationSection_content">;
  readonly " $fragmentType": "SidebarQuirky_content";
};
export type SidebarQuirky_content$key = {
  readonly " $data"?: SidebarQuirky_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"SidebarQuirky_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SidebarQuirky_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SidebarVerificationSection_content"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Menu",
          "kind": "LinkedField",
          "name": "menu",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuSearch",
              "kind": "LinkedField",
              "name": "search",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gameListId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4583b959903a11db3b7f2d2d7791c1e7";

export default node;
